"use strict";

/**
 * @ngdoc function
 * @name informaApp.directive:infTableChart
 * @description
 * # infTableChart
 * Directive of the informaApp
 */
angular.module("informaApp")
  .directive("infTableChart", ['$rootScope', function ($rootScope) {

    return{
      restrict: "E",
      replace: true,
      scope:{
        data: "=",
        metric: "=",
        advsusp: "="
      },
      link: function(scope, el, attrs, ctrl){
        scope.phases = ["i", "ii", "iii", "nda"];
        scope.redraw = function(data){

          var existing = d3.select(el[0]).selectAll("div.chart-row").remove(); //remove existing chart-rows

          if(data === undefined) { return; }

          var _this = this,
            width = 106;

          if(scope.metric === "loa" || scope.metric === "pts" || scope.metric === "dur") {
            if(scope.metric === "loa" || scope.metric === "pts"){
              var xScale = d3.scale.linear()
                .domain([0, 100])
                .range([0, width]);

            }else{
              xScale = d3.scale.linear()
                .domain([0, d3.max(data, function(d, i) { return +d; })])   // convert strings to numbers (prevent width style issue)
                .range([0, width]);
            }

            var row = d3.select(el[0]).selectAll("div")
                .data(data)
                .enter()
                .append("div")
                .classed("chart-row", true)
                .each(function(d, i){
                    d3.select(this).classed(scope.phases[i], true)
                });

              var number = row
                .append("span")
                .classed("caption", true)
                .text(function(d, i){
                  return d;
                });

              var column = row
                .append("div")
                .classed("chart-column", true);

              var bar = column
                  .append("div")
                  .attr("class", function(d, i){
                    return "bar " + scope.phases[i];
                  })
                  .style("width", function(d, i) {
                    return xScale(d) + "px";
                  });

          } else if(scope.metric === "count") {

            var data = scope.advsusp;

            var xScale = d3.scale.linear()
              .domain([0, d3.max(data, function(d, i){
                return d[0] + d[1];
              })])
              .range([0, width-5]); // We need to make sure there's space for the gap between the bars :/

              var row = d3.select(el[0]).selectAll("div")
                .data(data)
                .enter()
                .append("div")
                .classed("chart-row", true)
                .each(function(d, i){
                  d3.select(this).classed(scope.phases[i], true)
                });

              var number1 = row
                .append("span")
                .classed("caption", true)
                .text(function(d, i){
                  return d[0];
                });

              var number2 = row
                .append("span")
                .classed("caption", true)
                .text(function(d, i){
                  return d[1];
                });

              var number3 = row
                .append("span")
                .classed("caption", true)
                .text(function(d, i){
                  return d[0] + d[1];
                });

              var column = row
                .append("div")
                .classed("chart-column", true);

              var bar = column
                  .append("div")
                  .attr("class", function(d, i){
                    return "bar " + scope.phases[i];
                  })
                  .style("width", function(d, i) {
                    return xScale(d[0]) + "px";
                  })
                  .each(function(d, i){
                    var susp = d3.select(this.parentNode)
                      .append("div")
                      .classed("bar susp " + scope.phases[i], true)
                      .style("width", function(d, i) {
                        return xScale(d[1]) + "px";
                      });
                  });
            }
        };

        scope.$watchCollection("data", function(newVal){
          var data = [];
          _.forEach(scope.phases, function(v, i){
            //parsed-data.push(_.toNumber(newVal[i])); //convert from parsed-data/strings to a numeric type
            data.push(newVal[i]);
          });
          scope.redraw(data);
        });

      }
    }

  }]);
